import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import { Address } from '#mrktbox/types';
import { useNavigation, useCustomers, useSubscriptions } from '#mrktbox';
import { formatDateTime, formats } from '#mrktbox/utils';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useMap from '#hooks/useMap';
import useModal from '#hooks/useModal';
import useRequests from '#hooks/useRequests';

import Heading from '#materials/Heading';
import Preface from '#materials/Preface';
import Body from '#materials/Body';
import ButtonStyled from '#materials/ButtonStyled';
import ButtonLink from '#materials/ButtonLink';
import AddressSearch from '#materials/AddressSearch';
import Map from '#materials/Map';

import Main from '#components/page/Main';
import PageTitle from '#components/page/PageTitle';
import ScreenreaderTitle from '#components/page/ScreenreaderTitle';

import LocationsSlice from '#slices/Locations';

import SubscriptionChange from '#components/orders/SubscriptionChange';

interface Style { theme? : Theme; }
interface MapViewStyle extends Style { showMap? : boolean; }

const LocationsView = styled.div<Style>`
  padding: 0 ${(props) => props.theme.layout.padding};
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`;

const DeliveryButtonView = styled.div<Style>`
  max-width: 20rem;
  margin: ${(props) => props.theme.layout.padding} 0;
`;

const MapView = styled.div<MapViewStyle>`
  display: None;
  height: 0;
  margin: 0 -${(props) => props.theme.layout.paddingMobile};
  padding: 0 0 ${(props) => props.theme.layout.padding};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: block;
    height: 40rem;
    padding-bottom: ${(props) => props.theme.layout.paddingMobile};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: ${(props) => props.showMap ? '40rem' : '0'};
    transition: height 0.5s ease-in-out;
    padding-bottom: ${(props) =>
      props.showMap ? props.theme.layout.paddingMobile : '0'
    };
  }
`;

const LocationsSelectShowMap = styled.div<Style>`
  display: none;
  width: 100%;
  margin: 0.5rem 0 1rem;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }

  button {
    display: inline-block;
    padding: 0.5rem 0;
  }

  span {
    pointer-events: none;
    display: block;
    line-height: 1;
    color: ${(props) => props.theme.links.primary.colour};
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`;

const LocationsSelectTitle = styled.div<Style>`
  margin: 4rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 3rem 0 0;
  }

  h2 {
    font-size: ${(props) => props.theme.fonts.sizes.xBig};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.big};
    }
  }

  & > p {
    margin: 1rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.small};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin: 1rem 0 0;
        // font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    }
  }
`;

const DeliveryPickupButtons = styled.div<Style>`
  display: flex;
  gap: 2%;
  align-items: center;
  padding-bottom: 10%;
`;

function Locations() {
  const { navigate } = useNavigation();
  const { brand } = useConfig();
  const { openMap, closeMap } = useMap();
  const { openModal, closeModal } = useModal();
  const { addAddressToCustomer } = useCustomers();
  const { isOrderRecurring } = useSubscriptions();
  const {
    waiting,
    currentOrder,
    deliveryServices,
    pickupServices,
    checkAddress,
    proposeTime,
    updateOrder,
    setAddress : setOrderAddress,
    serviceChannel,
  } = useRequests();

  const [address, setAddress] = useState(
    currentOrder?.address
      ?? (currentOrder?.customer?.addresses
        ? (Object.values(currentOrder.customer.addresses)[0] ?? null)
        : null)
  );
  const [deliveryAvailable, setDeliveryAvailable] = useState(false);
  const [open, setOpen] = useState(false);

  const [showAddress, setShowAddress] = useState(
    !!serviceChannel && !pickupServices(serviceChannel).length
  );

  const handleSelectAddress = useCallback(async () => {
    if (!address) return;

    const customer = currentOrder?.customer;
    if (customer && (
      !customer?.addresses
        || !Object.values(customer.addresses).some((a) => a.id === address.id)
    )) {
      addAddressToCustomer(customer, address);
    }

    const proposedTime = proposeTime({ address });
    if (
      !currentOrder
        || (currentOrder?.complete && !proposedTime?.selection)
    ) return;

    const update = async (target ?: 'this' | 'future') => {
      const success = currentOrder?.complete
        ? await updateOrder({
          address,
          location : null,
          timeSlot : proposedTime.selection?.timeSlot,
          iteration : proposedTime.selection?.iteration,
          division : proposedTime.selection?.division,
        }, {
          moveItems : true,
          target,
        })
        : await setOrderAddress(address, {
          moveItems : true,
          target,
        })
      if (success) {
        navigate('/');
        closeModal();
      }
    }

    if (isOrderRecurring(currentOrder)) {
      openModal(
        <SubscriptionChange
          title="Update all subscriptions, or just this order?"
          prompt={`This order contains subscription items.\n Would you like to`
            + ' apply changes to this order only'
            + (currentOrder.time
              ? ` (${formatDateTime(currentOrder.time, formats.easy)})`
              : '')
            + ', or all future orders?'}
          selectTarget={update}
        />
      );
      return;
    }

    update();
  }, [
    address,
    currentOrder,
    proposeTime,
    updateOrder,
    setOrderAddress,
    addAddressToCustomer,
    isOrderRecurring,
    openModal,
    closeModal,
    navigate,
  ]);

  const toggleOpen = useCallback(() => setOpen(!open), [open, setOpen]);

  const handleShowAddress = useCallback(() => {
    setShowAddress(true);
  }, [setShowAddress]);

  const handleShowLocations = useCallback(() => {
    setShowAddress(false);
  }, [setShowAddress]);

  useEffect(() => {
    if (!address) {
      setDeliveryAvailable(false);
      return;
    }
    setDeliveryAvailable(checkAddress(address));
  }, [address, checkAddress]);

  useEffect(() => {
    openMap();
    return () => closeMap();
  }, [openMap, closeMap]);

  const customerAddresses = useMemo(() => (
    currentOrder?.customer?.addresses
      ? Object.values(currentOrder.customer.addresses)
      : []
  ), [currentOrder]);

  const isPickup = serviceChannel && !!pickupServices(serviceChannel).length
  const isDelivery = serviceChannel && !!deliveryServices(serviceChannel).length
  const isBoth = isPickup && isDelivery

  const proposedTime = address ? proposeTime({ address }) : null;

  const deliveryTitle = deliveryAvailable
    ? 'We can deliver!'
    : 'Sorry, delivery is not available in your neighborhood.'
  const deliverySubtitle = deliveryAvailable
    ? (proposedTime?.time
      ? 'Next available delivery '
        + formatDateTime(proposedTime.time, formats.easy)
      : 'Enter your address to check delivery availability.'
    )
    : "Take a look at the locations below, to see where " +
      "can pick up your order. If you believe there's been a " +
      "mistake, please reach out."

  return (
    <>
      <Helmet>
        <title>Locations | {brand.title}</title>
      </Helmet>
      <Main>
        <ScreenreaderTitle>Locations</ScreenreaderTitle>
        <LocationsView>
          <PageTitle
            title={
              isBoth ? (
                'Will you come to us, or should we come to you?'
              ) : (
                isPickup ? (
                  'Visit one of our locations.'
                ) : (
                  'Have the goods delivered.'
                )
              )
            }
            subtitle={
              isBoth ? (
                'Choose pickup to select a location, '
                  + 'or delivery to check your address.'
              ) : (
                isPickup ? (
                  'Select a location to pick up your order.'
                ) : (
                  'Enter your address to check delivery availability.'
                )
              )
            }
            textAlign='left'
            fullWidth
          />

          { isBoth && (
            <>
              <DeliveryPickupButtons>
                <ButtonStyled
                  size={'default'}
                  onClick={handleShowLocations}
                  disabled={!showAddress}
                >
                  Pickup
                </ButtonStyled>
                <ButtonStyled
                  size={'default'}
                  onClick={handleShowAddress}
                  disabled={showAddress}
                >
                   Delivery
                </ButtonStyled>
              </DeliveryPickupButtons>
            </>
          ) }
          { showAddress && (
            <>
              <AddressSearch
                address={address}
                setAddress={setAddress}
                defaults={customerAddresses}
              />
              <LocationsSelectTitle>
                { address
                  && (<>
                    <Heading as="h2">{ deliveryTitle }</Heading>
                    <Body as="p">{ deliverySubtitle }</Body>
                    { deliveryAvailable && (
                      <DeliveryButtonView>
                        <ButtonStyled
                          onClick={handleSelectAddress}
                          disabled={waiting}
                        >
                          Schedule Delivery
                        </ButtonStyled>
                      </DeliveryButtonView>
                    ) }
                  </>)
                }
                </LocationsSelectTitle>
            </>
          ) }
          <MapView showMap={open}>
            <Map />
          </MapView>
          <LocationsSelectShowMap>
            <ButtonLink onClick={toggleOpen}>
              <Preface>{open ? 'Hide Map' : 'Show Map'}</Preface>
            </ButtonLink>
          </LocationsSelectShowMap>
          { !showAddress && (
            <LocationsSlice />
          ) }
        </LocationsView>
      </Main>
    </>
  );
}

export default Locations;
