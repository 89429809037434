import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import useRequests from '#hooks/useRequests';

import ButtonStyled from '#materials/ButtonStyled';

import PageContent from '#components/page/PageContent';
import PageTitle from '#components/page/PageTitle';
import OrderList from '#components/orders/OrderList';

const ButtonView = styled.div`
  display: flex;
  gap: 1rem;
  width: fit-content;
  margin: auto;
`;

function Orders() {
  const {
    getOpenOrders,
    getClosedOrders,
    getDraftOrders,
    getUpcomingOrders,
    getRecentOrders,
  } = useRequests();

  const [showAllUpcoming, setShowAllUpcoming] = useState(false);
  const [showHistory, setShowHistory] = useState(1);

  const past = useMemo(() => getClosedOrders(), [getClosedOrders]);
  const upcoming = useMemo(() => getOpenOrders(), [getOpenOrders]);
  const drafts = useMemo(() => getDraftOrders(), [getDraftOrders]);
  const soon = useMemo(() => getUpcomingOrders(), [getUpcomingOrders]);
  const recent = useMemo(
    () => getRecentOrders({ pages : showHistory }),
    [getRecentOrders, showHistory]
  );

  return (
    <>
      { !!drafts.length && (
        <>
          <PageTitle
            title="Draft Orders"
            subtitle="Finish and submit your orders"
          />
          <OrderList orders={drafts} />
        </>
      ) }
      <PageTitle
        title="Upcoming Orders"
        subtitle="Manage your current orders"
      />
      { !!upcoming.length
        ? (
          <>
            <OrderList
              orders={upcoming}
              pageCount={showAllUpcoming ? undefined : soon.length}
            />
            { soon.length < upcoming.length && (
              <ButtonView>
                <ButtonStyled
                  onClick={() => setShowAllUpcoming(!showAllUpcoming)}
                >
                  { showAllUpcoming ? 'Hide' : 'Show All' }
                </ButtonStyled>
              </ButtonView>
            ) }
          </>
        ) : (
          <PageContent>
            <p>Looks like you don't have any upcoming orders.</p>
          </PageContent>
        )
      }
      <PageTitle
        title='Order History'
        subtitle={'Reorder past orders, ' +
          'or add order ratings & comments.'}
        style={{ marginTop : '8rem' }}
      />
      { past.length
        ? (
          <>
            <OrderList orders={recent} />
            { !((recent.length === past.length) && (showHistory < 2)) && (
              <ButtonView>
                <ButtonStyled
                  onClick={() => setShowHistory(showHistory + 1)}
                  disabled={recent.length === past.length}
                >
                  Show More
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => setShowHistory(showHistory - 1)}
                  disabled={showHistory < 2}
                >
                  Show Less
                </ButtonStyled>
              </ButtonView>
            ) }
          </>
        ) : (
          <PageContent>
            <p>Looks like you don't have any past orders.</p>
          </PageContent>
        )
      }
    </>
  );
}

export default Orders;
